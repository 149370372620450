<template>
  <div :class="isSeoPage">
    <wl-header
      id="header"
      :path="path"
      :is-fixed="isFixed || isSEO"
      :hide-menu="isLanding"
      :class="{
        'wl-header--mobile-search': mobileSearchActive,
        'wl-header--has-auxiliary-menu': isTablet,
        'wl-header--home': isHome
      }"
      :logo-name="header.name"
      :logo-title="header.logo_title"
    >
      <template #header-logo>
        <a
          class="wl-header__logo"
          href="/"
          :title="header.logo_title"
          aria-labelledby="Home"
        >
          <span class="wl-header__logo-image" v-text="logoInnerText" />
          <span class="wl-header__logo-text" v-text="'PeopleSearch'" />
          <span class="wl-header__logo-tld" v-text="'.com'" />
        </a>
      </template>
      <template
        #header-bar
        v-for="headerBar in resultHelper.renderIfReturnArray(!isLanding)"
      >
        <button
          type="button"
          id="searchToggle"
          class="fps-search-toggle"
          aria-controls="searchBar"
          aria-expanded="false"
          aria-label=""
          v-touch:tap="mobileSearchOpen"
        >
          Search By Name
          <img
            :src="header.mobile_search_icon"
            alt="Search Icon"
            loading="lazy"
            width="15px"
            height="15px"
          />
        </button>
        <span class="fps-search-cancel">
          Search By Name
          <button
            type="button"
            id="searchClose"
            @click.self="mobileSearchClose"
          />
        </span>
        <tz-search
          :in-header="true"
          :prefill-fields="prefillFields"
          :fallback-fields="fallbackFields"
          :search-icon="isMobile ? longArrowImg : null"
          :people-link="'https://freepeoplesearch.com/searching/'"
          :noQueryString="true"
          :people-invalid-regex="getRegex({ regexType: 'peopleInvalidRegex' })"
          @tz-search-override-redirect="redirectToSeo"
        />
        <ul id="toggle-menu" class="wl-header__toggle-menu">
          <li
            class="wl-header__toggle-menu-item"
            :key="link.label"
            v-for="link in header.menu"
          >
            <a
              class="wl-header__toggle-menu-link"
              :class="{
                'wl-header__toggle-menu-link--active': path === link.url
              }"
              :href="link.url"
              v-text="link.label"
            />
          </li>
        </ul>
      </template>
    </wl-header>
    <main class="inside-content">
      <nuxt ref="page" :prefill-fields="prefillFields" />
      <div class="footer-states" v-if="!isLanding">
        <div class="footer-states__container">
          <ul class="footer-states__list">
            <li
              class="footer-states__item"
              v-for="(stateName, stateAbbr) in getStates()"
              :key="stateAbbr"
            >
              <a
                class="footer-states__link"
                :href="`/directory/states/${stateAbbr}/`"
                v-text="stateAbbr"
              />
            </li>
          </ul>
        </div>
      </div>
      <tz-footer
        :class="{
          'tz-footer--landing': isLanding,
          'tz-footer--default': !isLanding,
          'tz-footer--mg-sm': isProfile || isSEO
        }"
        :page="page"
        header-fixed=""
        :footer="
          isLanding ? { ...footer, disclaimer: landingDisclaimer } : footer
        "
        alpha-base-link="/directory/"
        :display-sections="
          isLanding
            ? ['globalLinks', 'disclaimer']
            : [
                'alphaSelect',
                'contactInfo',
                'columnSecondary',
                'columnTertiary',
                'columnQuarternary',
                'disclaimer'
              ]
        "
        :is-alpha-lower="lowercaseLinks"
        :has-trailing-slash="true"
      >
        <template v-slot:badges>
          <img :src="securityBadges" alt="Trusted site" />
        </template>
      </tz-footer>
    </main>
  </div>
</template>

<script>
// Component
import WlHeader from '@/components/shared/wl-header.vue';
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
// Images
import securityBadges from '@/assets/images/logos/security-badges.webp';
import longArrowImg from '@/assets/images/icons/long-arrow.svg';
// Misc
import resultHelper from '@/assets/js/shared/resultHelper';
import { unregisterAndClearCaches } from '@/assets/js/shared/service-worker';
import formatNameCaseWithSplitterAndSpacer from '@/assets/js/shared/helpers/formatNameCaseWithSplitterAndSpacer';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import userLocation from '@/mixins/shared/userLocation';
import getStates from '@/assets/js/shared/helpers/getStatesList';
import mediaQueryListener from '@/assets/js/shared/helpers/mediaQueryListener';
import getMobileQuery from '@/assets/js/shared/helpers/getMobileQuery';
const mobileMediaQuery = getMobileQuery({ width: 991 });
const tabletMediaQuery = getMobileQuery({ width: 1200 });
const headerCMS = require(`@/assets/cms/components/header.json`);
const footerCMS = require(`@/assets/cms/components/footer.json`);

let insertionIndex = 1;

for (let i = 1; i < 13; i++) {
  const today = new Date();
  const priorDate = new Date(
    new Date().setDate(today.getDate() - i)
  ).toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  }); // returns MM/DD/YYYY
  const mm = priorDate.split('/')[0];
  const m = priorDate.split('/')[0].replace(/^0/, '');
  const dd = priorDate.split('/')[1];
  const d = priorDate.split('/')[1].replace(/^0/, '');
  const yyy = priorDate.split('/')[2];

  // TODO: update URL with correct link once pages are built
  const dateEntry = {
    label: `${mm}/${dd}`,
    url: `/top-baby-names/${yyy}-${m}-${d}/`
  };

  footerCMS.quarternary_list.splice(insertionIndex, 0, dateEntry);
  insertionIndex++;
}

export default {
  name: 'freepeoplesearch',
  mixins: [userLocation],
  components: {
    WlHeader,
    TzSearch,
    TzFooter
  },
  data() {
    return {
      getStates,
      securityBadges,
      longArrowImg,
      page: {
        path: this.$route.path,
        name: this.$route.name,
        params: this.$route.params
      },
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state']
      },
      logoSize: { height: '47', width: '200' },
      logoSmallSize: { height: '69', width: '100' },
      menuLinksDesktopCount: headerCMS.menu.length < 3 ? 0 : 3,
      isMobile: false,
      isTablet: false,
      mobileSearchActive: false,
      path: null,
      isFixed: false,
      lowercaseLinks: true,
      fallbackFields: [{ aid: 27, sid: 'FPS' }, {}],
      resultHelper,
      getRegex
    };
  },
  async created() {
    this.path = this.$route.path;
    const params = {};
    if (this.isSEO) {
      if (this.$route.params.firstName) {
        params.firstName = formatNameCaseWithSplitterAndSpacer({
          name: this.$route.params.firstName
        });
      }
      if (this.$route.params.lastName) {
        params.lastName = formatNameCaseWithSplitterAndSpacer({
          name: this.$route.params.lastName,
          separator: '_'
        });
      }
      if (this.$route.params.city) {
        params.city = formatNameCaseWithSplitterAndSpacer({
          name: this.$route.params.city.replace(/-|_/g, ' ')
        });
      }
      if (this.$route.params.state) {
        params.state = this.$route.params.state;
      }
      this.updatePrefillFields(params);
    }
  },
  computed: {
    isHome() {
      return this.$route.path === '/';
    },
    isLanding() {
      return (
        this.$route.name?.includes('landing') ||
        this.$route.name?.includes('flow.')
      );
    },
    isProfile() {
      return this.$route.name?.includes('seo.profile');
    },
    isSEO() {
      return this.$route.name?.includes('seo');
    },
    landingDisclaimer() {
      return this.footer.landing_disclaimer;
    },
    logoInnerText() {
      if (this.isSEO && this.isMobile) {
        return 'FREE';
      }
      if (this.isMobile && this.isFixed) {
        return 'FPS';
      } else {
        return 'FREE';
      }
    },
    menu() {
      return this.isSEO || this.isFixed || this.isMobile || this.isTablet
        ? []
        : this.header.menu.slice(0, this.menuLinksDesktopCount);
    },
    searchOffsetElement() {
      // TODO: Only return until elements are added to page
      return {
        offsetTop: 0,
        offsetHeight: 20
      };

      const bannerSlots = this.$refs.insideContent.querySelector(
        '.tz-banner__slots'
      );

      // If there is a banner search, show and hide header search when scrolled past ex. Home Page
      if (bannerSlots && bannerSlots.offsetHeight > 0) {
        return bannerSlots;
      }

      const bannerDesc = this.$refs.insideContent.querySelector(
        '.tz-banner__desc'
      );

      // If there is a banner with a header, show and hide search when scrolled past ex. Privacy Policy
      if (bannerDesc && bannerDesc.offsetHeight > 0) {
        return bannerDesc;
      }

      // Otherwise default to breadcrumbs ex. City Results Page
      return this.$refs.insideContent.querySelector('.tz-breadcrumbs');
    },
    isSeoPage() {
      // prevents empty class attribute on non seo pages
      if (!this.isSEO) {
        return;
      }

      return 'fp-seo';
    }
  },
  methods: {
    updatePrefillFields(updatedFields) {
      this.prefillFields = Object.assign({}, this.prefillFields, updatedFields);
    },
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    setIsTablet(e) {
      this.isTablet = e.matches;
    },
    setScrollEvent() {
      if (this.isLanding) return;
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    },
    handleScroll() {
      this.searchNotVisibleOffset =
        this.searchOffsetElement.offsetTop +
        this.searchOffsetElement.offsetHeight;
      this.isFixed = window.scrollY >= this.searchNotVisibleOffset;
    },
    mobileSearchOpen() {
      this.mobileSearchActive = true;
    },
    mobileSearchClose() {
      this.mobileSearchActive = false;
    },
    redirectToSeo(submittedFields) {
      let url = `${this.$config.baseUrl}/`;
      if (!submittedFields.lastName) {
        return;
      }

      url += `${submittedFields.lastName}/${submittedFields.firstName}`;

      if (!submittedFields.state) {
        window.location = url;
        return;
      }
      window.location = `${url}/${submittedFields.state}/${submittedFields.city}`;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    if (mobileMediaQuery && mobileMediaQuery.removeEventListener) {
      mobileMediaQuery.removeEventListener('change', this.setIsMobile);
    }
  },
  async mounted() {
    try {
      await unregisterAndClearCaches();
    } catch (err) {
      // ignore if error is thrown
      this.$bugsnag.notify(err);
    }

    mediaQueryListener({
      selector: mobileMediaQuery,
      handleMethod: this.setIsMobile
    });
    this.isMobile = mobileMediaQuery.matches;

    mediaQueryListener({
      selector: tabletMediaQuery,
      handleMethod: this.setIsTablet
    });
    this.isTablet = tabletMediaQuery.matches;

    this.handleScroll();
    this.setScrollEvent();

    // Remove any registered service workers
    unregisterAndClearCaches();
  },
  props: {
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    },
    footer: {
      type: Object,
      default: () => require(`@/assets/cms/components/footer.json`)
    }
  },
  head() {
    const head = {
      meta: [],
      script: [],
      link: []
    };
    const hasNoCrawling = /landing|flow/.test(this.$route.name);

    // If route has no crawling add noindex nofollow noarchive and return early
    if (hasNoCrawling) {
      head.meta.push({
        name: 'robots',
        hid: 'robots',
        content: 'noindex, nofollow, noarchive'
      });

      return head;
    }
    const isSeoPage = /seo/.test(this.$route.name);
    const isDirectoryPage = /directory/.test(this.$route.path);
    const website = `https://freepeoplesearch.com`;
    const path =
      this.$route.path.slice(-1) === '/'
        ? this.$route.path
        : this.$route.path + '/';

    // seo pages have their own canonical link logic that accounts for title casing
    if (!isSeoPage && this.$route.name) {
      let canonicalPath = path.toLowerCase();
      switch (this.$route.name) {
        // Upper cases the params
        case 'index.cities':
          const pattern = /\/([^/]+)\/$/; // gets the last string before the ending slash
          const match = path.match(pattern);
          const lastPiece = match[1];
          const updatedLastPiece = lastPiece.toUpperCase();
          const updatedPath =
            path.toLowerCase().slice(0, match.index + 1) +
            updatedLastPiece +
            '/';
          canonicalPath = `${website}${updatedPath}`;
          break;
        default:
          canonicalPath = `${website}${path}`;
      }

      head.link = [
        {
          rel: 'canonical',
          href: canonicalPath
        }
      ];
    }
    // Directory pages will have canonical link handled in its own page
    if (isDirectoryPage) {
      head.link = [];
    }
    // Setup nav links for nav schema
    // TODO: Update the letter index links
    const navLinks = this.header.menu
      .concat(
        [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map(letter => ({
          url: `/people/${letter}000000/`,
          name: letter
        }))
      )
      .concat(this.footer.secondary_list)
      .concat(this.footer.global_list);
    const navSchema = [];
    // Loop through links to build nav schema
    for (let i = 0; i < navLinks.length; i++) {
      const link = navLinks[i].url.startsWith('/')
        ? website + navLinks[i].url
        : navLinks[i].url;
      navSchema.push({
        '@type': 'SiteNavigationElement',
        position: i + 1,
        '@id': link,
        url: link,
        name: navLinks[i].label
      });
    }
    // Push nav schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: navSchema
      }
    });

    // Push org schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'FreePeopleSearch',
        url: website,
        alternateName: 'FreePeopleSearch.com',
        logo: `${website}/assets/images/logos/logo.svg`,
        contactPoint: [
          {
            '@type': 'ContactPoint',
            email: 'contact@freepeoplesearch.com',
            url: `${website}/contact/`,
            contactType: 'Customer Service',
            areaServed: 'US'
          }
        ]
      }
    });

    return head;
  }
};
</script>
