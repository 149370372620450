<template>
  <section class="fp-error">
    <div class="fp-error__inner">
      <header class="fp-error__caption">
        <div class="fp-error__status">404</div>
        <h1 class="fp-error__heading">This page could not be found</h1>
        <p class="fp-error__description">
          Please continue your search in the form below.
        </p>
      </header>

      <tz-search
        class="fp-error__search"
        :people-link="peopleURL"
        :search-icon="longArrowImg"
        :prefill-fields="prefillFields"
        :fallback-fields="fallbackFields"
        :people-invalid-regex="getRegex({ regexType: 'peopleInvalidRegex' })"
      ></tz-search>
    </div>
  </section>
</template>
<script>
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import longArrowImg from '@/assets/images/icons/long-arrow.svg';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import Meta from '@/assets/js/shared/misc/meta.mjs';

const cms = require(`@/assets/cms/pages/error.json`);

export default {
  components: {
    TzSearch
  },
  data() {
    return {
      peopleURL: 'https://freepeoplesearch.com/searching',
      fallbackFields: [{ aid: 11 }, {}],
      longArrowImg,
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state']
      },
      getRegex
    };
  },
  mounted() {
    this.$bugsnag.notify(new Error('404 Error'));
  },
  head() {
    return {
      title: cms.page_title ?? '404 Error',
      meta: cms.meta ? Meta.convertToNuxtMeta(cms.meta) : []
    };
  },
  props: ['error']
};
</script>
