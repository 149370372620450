import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';

export default function({ route, redirect }) {
  const { params, fullPath } = route;
  let redirectParams = {};
  let redirectUrl = '/directory/states/';
  const stateParam = params.state ? params.state.toUpperCase() : '';
  const hasValidState =
    stateParam && !!getStateFromAbbr({ stateAbbr: stateParam });

  if (hasValidState) {
    redirectParams.state = stateParam;
    redirectUrl = redirectUrl + redirectParams.state + '/';
  } else {
    return redirect(301, redirectUrl);
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
