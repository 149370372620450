import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _de783be8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "" */))
const _41d65b9f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "" */))
const _2bd1be42 = () => interopDefault(import('../pages/content/index.vue' /* webpackChunkName: "" */))
const _62a998f4 = () => interopDefault(import('../pages/dos-donts/index.vue' /* webpackChunkName: "" */))
const _97234e22 = () => interopDefault(import('../pages/top-full-names/index.vue' /* webpackChunkName: "" */))
const _683bfd2e = () => interopDefault(import('../pages/top-full-names/state.vue' /* webpackChunkName: "" */))
const _0393099c = () => interopDefault(import('../pages/trending/day.vue' /* webpackChunkName: "" */))
const _c49f2c5c = () => interopDefault(import('../pages/trending/index.vue' /* webpackChunkName: "" */))
const _13944a14 = () => interopDefault(import('../pages/top-last-names/index.vue' /* webpackChunkName: "" */))
const _4b05d902 = () => interopDefault(import('../pages/top-last-names/alphabet.vue' /* webpackChunkName: "" */))
const _1c88a69c = () => interopDefault(import('../pages/top-last-names/letter.vue' /* webpackChunkName: "" */))
const _cbcfeee4 = () => interopDefault(import('../pages/top-last-names/states.vue' /* webpackChunkName: "" */))
const _abf90196 = () => interopDefault(import('../pages/top-last-names/state.vue' /* webpackChunkName: "" */))
const _b317f894 = () => interopDefault(import('../pages/directory/index.vue' /* webpackChunkName: "" */))
const _09fa39b4 = () => interopDefault(import('../pages/directory/streets.vue' /* webpackChunkName: "" */))
const _966c8e52 = () => interopDefault(import('../pages/bucket/index.vue' /* webpackChunkName: "" */))
const _45988070 = () => interopDefault(import('../pages/landing/searching.vue' /* webpackChunkName: "" */))
const _11d04168 = () => interopDefault(import('../pages/landing/no-results.vue' /* webpackChunkName: "" */))
const _c421512e = () => interopDefault(import('../pages/step/alt-one.vue' /* webpackChunkName: "" */))
const _9351ef62 = () => interopDefault(import('../pages/step/alt-two.vue' /* webpackChunkName: "" */))
const _620243e1 = () => interopDefault(import('../pages/step/alt-three.vue' /* webpackChunkName: "" */))
const _afa66dfc = () => interopDefault(import('../pages/optout/search.vue' /* webpackChunkName: "" */))
const _7684cdb6 = () => interopDefault(import('../pages/optout/request.vue' /* webpackChunkName: "" */))
const _483270e2 = () => interopDefault(import('../pages/optout/confirmation.vue' /* webpackChunkName: "" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "" */))
const _302c424a = () => interopDefault(import('../pages/seo/results.vue' /* webpackChunkName: "" */))
const _ca9fb606 = () => interopDefault(import('../pages/seo/lastname.vue' /* webpackChunkName: "" */))
const _59bb7e7d = () => interopDefault(import('../pages/seo/profile.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _de783be8,
    name: "home"
  }, {
    path: "/contact/",
    component: _1d238929,
    name: "contact"
  }, {
    path: "/frequently-asked-questions/",
    component: _41d65b9f,
    name: "faq"
  }, {
    path: "/privacy-policy/",
    component: _2bd1be42,
    name: "privacy-policy"
  }, {
    path: "/terms-conditions/",
    component: _2bd1be42,
    name: "terms-conditions"
  }, {
    path: "/dos-and-donts/",
    component: _62a998f4,
    name: "dos-and-donts"
  }, {
    path: "/baby-names/",
    component: _97234e22,
    name: "top-full-names.root"
  }, {
    path: "/baby-names/:state",
    component: _683bfd2e,
    name: "top-full-names.state"
  }, {
    path: "/top-baby-names/:year-:month-:day/",
    component: _0393099c,
    name: "trending.day"
  }, {
    path: "/top-baby-names/:year?",
    component: _c49f2c5c,
    name: "trending.index"
  }, {
    path: "/last-names/",
    component: _13944a14,
    name: "top-last-names.root"
  }, {
    path: "/last-names/letter/",
    component: _4b05d902,
    name: "top-last-names.alphabet"
  }, {
    path: "/last-names/letter/:letter",
    component: _1c88a69c,
    name: "top-last-names.letter"
  }, {
    path: "/last-names/state/",
    component: _cbcfeee4,
    name: "top-last-names.states"
  }, {
    path: "/last-names/state/:state",
    component: _abf90196,
    name: "top-last-names.state"
  }, {
    path: "/directory/",
    component: _b317f894,
    meta: {"redirect":"\u002Fdirectory\u002Fstates"},
    name: "directory"
  }, {
    path: "/directory/states",
    component: _b317f894,
    name: "index.state"
  }, {
    path: "/directory/states/:state",
    component: _b317f894,
    name: "index.cities"
  }, {
    path: "/directory/states/:state/:city",
    component: _09fa39b4,
    name: "index.zipcodes"
  }, {
    path: "/directory/states/:state/:city/:zip/:page(\\d+)?",
    component: _09fa39b4,
    name: "index.streets"
  }, {
    path: "/directory/states/:state/:city/:zip/:street/:page(\\d+)?",
    component: _09fa39b4,
    name: "index.streetnames"
  }, {
    path: "/directory/:letter",
    component: _966c8e52,
    name: "bucket.letter"
  }, {
    path: "/directory/:letter/:tier2",
    component: _966c8e52,
    name: "bucket.tier2"
  }, {
    path: "/directory/:letter/:tier2/:tier3",
    component: _966c8e52,
    name: "bucket.tier3"
  }, {
    path: "/searching/",
    component: _45988070,
    name: "landing.searching"
  }, {
    path: "/results/",
    component: _11d04168,
    name: "landing.results"
  }, {
    path: "/lnd/:landingID/a/",
    component: _c421512e,
    name: "flow.alt1"
  }, {
    path: "/lnd/:landingID/b/",
    component: _9351ef62,
    name: "flow.alt2"
  }, {
    path: "/lnd/:landingID/c/",
    component: _620243e1,
    name: "flow.alt3"
  }, {
    path: "/remove-info/",
    component: _afa66dfc,
    name: "optout"
  }, {
    path: "/remove-info/request/",
    component: _7684cdb6,
    name: "optout.request"
  }, {
    path: "/remove-info/confirmation/:token",
    component: _483270e2,
    name: "optout.confirmation"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/phone-number/:phone/:page(\\d+)?",
    component: _302c424a,
    name: "seo.phone"
  }, {
    path: "/location/:zipcode/:state/:city/:street/:page(\\d+)?",
    component: _302c424a,
    name: "seo.address"
  }, {
    path: "/:lastname/",
    component: _ca9fb606,
    name: "seo.lastname"
  }, {
    path: "/:lastName/:firstName/:page(\\d+)?",
    component: _302c424a,
    name: "seo.root"
  }, {
    path: "/:lastName/:firstName/:state/:page(\\d+)?",
    component: _302c424a,
    name: "seo.state"
  }, {
    path: "/:lastName/:firstName/:state/:city/:page(\\d+)?",
    component: _302c424a,
    name: "seo.city"
  }, {
    path: "/:lastName/:firstName/:state/:city/:uuid/",
    component: _59bb7e7d,
    name: "seo.profile"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
