import resultHelper from '@/assets/js/shared/resultHelper';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getRegex from '@/assets/js/shared/helpers/getRegex';
import formatPhoneNumber from '@/assets/js/shared/helpers/formatPhoneNumber';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';

/**
 * Results Redirect Middleware
 * @param route
 * @param redirect
 * @returns {*}
 */
export default function({ route, redirect, error }) {
  const { params, fullPath, query } = route;
  const queryParams = Object.keys(query);
  const hasValidPage = params.page && params.page <= 10;
  const hasPhone = !!params.phone;
  const isPhoneValid =
    hasPhone && formatPhoneNumber({ phoneNumber: params.phone }) !== '';

  // SEO Phone Middleware
  if (route.name === 'seo.phone') {
    // If the phone number is not valid, return a 404
    if (!isPhoneValid) {
      return error({ statusCode: 404 });
    } else {
      // If the phone number is valid, redirect to the dashed version of the phone number
      return redirect(
        301,
        `/phone-number/${dashedPhoneNumber({
          phoneNumber: params.phone.replace(/\D+/g, '')
        })}/`
      );
    }
  }

  // SEO Address Middleware
  if (route.name === 'seo.address') {
    const redirectParams = {};
    const redirectStreet = setTitleCase({
      text: params.street.replace('-', ' ')
    }).replace(' ', '-');
    const redirectCity = setTitleCase({
      text: params.city.replace(getRegex({ regexType: 'underscoreRegEx' }), ' ')
    }).replace(getRegex({ regexType: 'spaceRegEx' }), '_');
    const redirectState = params.state.toUpperCase();
    let redirectPath = `/location/${params.zipcode}/${redirectState}/${redirectCity}/${redirectStreet}/`;

    if (hasValidPage) {
      redirectParams.page = params.page;
      redirectPath = redirectPath + redirectParams.page + '/';
    }
    if (fullPath !== redirectPath) {
      return redirect(301, redirectPath);
    }
    if (fullPath[fullPath.length - 1] !== '/') {
      return redirect(301, redirectPath + '/');
    }
    return;
  }
  const hasValidState =
    params.state && !!resultHelper.getStateFromAbbr(params.state);
  let redirectParams = {};
  const hasValidCity = hasValidState && params.city;
  const hasValidQueryParams = queryParams.length;
  redirectParams.firstName = setTitleCase({
    text: resultHelper.parseParamFirstName(params.firstName.toLowerCase())
  })
    .replace(resultHelper.spaceRegEx, '_')
    .replace(getRegex({ regexType: 'specialCharacters' }), '')
    .replace(resultHelper.dashRegEx, '');
  redirectParams.lastName = setTitleCase({
    text: resultHelper.parseParamLastName(params.lastName)
  })
    .replace(resultHelper.spaceRegEx, '_')
    .replace(getRegex({ regexType: 'specialCharacters' }), '');
  let redirectUrl = `/${redirectParams.lastName}/${redirectParams.firstName}/`;

  if (hasValidState) {
    redirectParams.stateAbbr = params.state.toUpperCase();
    redirectUrl = redirectUrl + redirectParams.stateAbbr;
    if (route.name === 'seo.state') {
      redirectUrl = redirectUrl + '/';
    }
  }

  if (hasValidCity) {
    redirectParams.city = setTitleCase({
      text: params.city.replace(resultHelper.underscoreRegEx, ' ')
    }).replace(resultHelper.spaceRegEx, '_');
    redirectUrl = redirectUrl + `/${redirectParams.city}/`;
  }

  if (hasValidPage) {
    redirectParams.page = params.page;
    redirectUrl = redirectUrl + redirectParams.page + '/';
  }

  if (hasValidQueryParams) {
    const queryString = queryParams
      .map(key => key + '=' + query[key])
      .join('&');
    redirectUrl = redirectUrl + '?' + queryString;
  }

  if (fullPath !== redirectUrl) {
    return redirect(301, redirectUrl);
  }
}
