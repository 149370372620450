/**
 * Trending Name Search Redirect Middleware
 * @param route
 * @param redirect
 */
export default function({ route, redirect }) {
  const { params } = route;
  const currentYear = new Date().getFullYear();
  const redirectUrl = '/top-baby-names/';
  const minYear = 1999;

  if (
    isNaN(params.year) ||
    params.year > currentYear ||
    params.year < minYear
  ) {
    redirect(redirectUrl);
  }
}
